import $ from "jquery";

setSectionHeight();

window.sectionResizeTimeout = null;
$(window).on("resize", function () {
	clearTimeout(window.sectionResizeTimeout);
	window.sectionResizeTimeout = setTimeout(() => {
		setSectionHeight();
	}, 500);
});

function setSectionHeight() {
	$(".js-split").each(function () {
		const outerTop = $(this).offset().top;
		const anchorTop = $(this).find(".js-split-anchor").offset().top;
		$(this)
			.find(".js-split-bg")
			.css("height", `${anchorTop - outerTop}px`);
	});
}
