import $ from "jquery";

export function loadDirectories({ page, postType, type, replace = false }) {
	// Posts elements
	const $grid = $(".js-directories-grid");
	const $loadMoreBtn = $(".js-load-more");

	if (replace) {
		$grid.css("opacity", 0);
	} else {
		$grid.css("opacity", 0.5);
	}

	const data = { action: "theme_get_directories" };

	console.log(data);

	// Only pass in data if provided
	if (page) {
		data.page = page;
	} else {
		// First time we request posts, we want page 2 as page 1 is currently displayed
		data.page = 2;
	}
	if (postType) {
		data.postType = postType;
	} else {
		data.postType = "directories";
	}

	if (type) {
		data.type = type;
	}

	$.ajax({
		url: "/wp-admin/admin-ajax.php",
		method: "GET",
		data: data,
		success: function (res) {
			if (res.success) {
				// Make sure grid has animated out before swapping out HTML.
				setTimeout(function () {
					if (replace) {
						$grid.html(res.html);
					} else {
						$grid.append(res.html);
					}
					if (res.load_more) {
						$grid.attr("data-page", res.next_page);
						$loadMoreBtn.show();
					} else {
						$loadMoreBtn.hide();
					}
					// Toggle load more button if there are more results.
					$grid.css("opacity", 1);
				}, 100);
			}
		},
	});
}
