( function( $ ) {

	$( document ).ready( function() {

		$( '.accordion-item__title' ).click( function() {
			$( this ).parent().toggleClass( 'active' );
			$( this ).parent().find( '.accordion-item__content' ).slideToggle();
		});

	});

}( jQuery ) );