( function( $ ) {

	$( document ).ready( function() {

		function toggleMobileDropdown() {

      $( '.js-dropdown > a' ).click( function( e ) {
					e.preventDefault();
					const $parent = $( this ).parent( '.js-dropdown' );
          $( '.js-dropdown__sub', $parent).slideToggle();
				});


			$( '.js-dropdown' ).each( function() {
				const link = $( 'a', $( this ) ).attr( 'href' );
				$( 'ul', $( this ) ).append( '<li><a href="' + link + '">View All</a></li>' );
			});

		}

		toggleMobileDropdown();

	});

}( jQuery ) );
