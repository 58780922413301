( function( $ ) {

	$( document ).ready( function() {

		function toggleNav() {

			$( '.js-mobile-btn' ).click( function( e ) {
				e.preventDefault();
				$( this ).toggleClass( 'js-mobile-btn--active' );
				$( '.js-mobile-nav' ).toggleClass( 'visible invisible opacity-0 opacity-100');
				$( 'body' ).toggleClass('overflow-hidden');
			});

		}

		toggleNav();

	});

}( jQuery ) );
