import $ from "jquery";
import { loadDirectories } from "../utils/fetch-data";

$(function () {
	// On posts load more click

	$(".js-load-more").on("click", function () {
		const postType = $(".js-load-more").attr("data-post-type") ?? "directories";
		loadDirectories({
			page: $(".js-directories-grid").attr("data-page"),
			postType,
		});
	});

	// Update directories on types change
	$(".btn__toggle").on("click", function (e) {
		e.preventDefault();

		const $activeBtn = $(".btn__toggle--active")[0];
		$(".btn__toggle").removeClass("btn__toggle--active");

		// If button is not active, make it active
		if (e.target !== $activeBtn) {
			$(this).addClass("btn__toggle--active");
		}

		let data = {
			page: 1,
			postType: "directories",
			replace: true,
		};

		// Pass term to request if exists and if not deselecting an option
		if ($(this).attr("data-term") && e.target !== $activeBtn) {
			data.type = $(this).attr("data-term");
		}

		loadDirectories(data);
	});
});
