const form = document.querySelector(".form-option");
const toggleInputs = document.querySelectorAll(".form-toggle input");
const formTypes = form?.querySelectorAll(".form-type");
const formMember = document?.querySelector(".form-type.password");
const toggleClass = "hidden";

for (const toggleInput of toggleInputs) {
	toggleInput.addEventListener("input", function () {
		formMember.classList.add(toggleClass);
		for (const formType of formTypes) {
			formType.classList.add(toggleClass);
		}
		const activeForms = form.querySelectorAll(`.form-type.${toggleInput.id}`);
		for (const activeForm of activeForms) {
			activeForm.classList.remove(toggleClass);
		}
	});
}